import React from "react";
import { Button, Container, Paper, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const ErrorView: React.FC = () => {
  return (
    <Paper
      elevation={0}
      className="flex items-center justify-center min-h-screen"
    >
      <Container maxWidth="sm">
        <Paper elevation={3} className="p-8 text-center">
          <ErrorOutlineIcon fontSize="large" color="error" className="mb-6" />
          <Typography variant="h5" gutterBottom fontWeight="medium">
            Parece que algo deu errado
          </Typography>
          <Typography gutterBottom>
            Por favor, recarregue a página e tente novamente, ou navegue para
            outra página.
          </Typography>
          <Button
            startIcon={<RefreshIcon />}
            onClick={() => window.location.reload()}
          >
            Recarregar
          </Button>
        </Paper>
      </Container>
    </Paper>
  );
};

export default ErrorView;
