import { PropsWithChildren } from "react";
import { createTheme, ThemeProvider, useMediaQuery } from "@mui/material";
import {
  brown,
  green,
  indigo,
  lightBlue,
  orange,
  red,
} from "@mui/material/colors";
import { ptBR } from "@mui/x-date-pickers/locales";

type Params = {
  systemPreference: "light" | "dark";
};

const theme = ({ systemPreference }: Params) => {
  const isDark = systemPreference === "dark";
  return createTheme(
    {
      typography: {
        fontFamily: '"Montserrat Variable", sans-serif',
      },
      components: {
        MuiIconButton: {
          defaultProps: {
            color: "inherit",
          },
        },
        MuiAppBar: {
          defaultProps: {
            color: "inherit",
          },
        },
      },
      palette: {
        mode: systemPreference,
        primary: {
          main: isDark ? brown[200] : brown[600],
        },
        secondary: {
          main: isDark ? indigo[200] : indigo[600],
        },
        error: {
          main: isDark ? red[200] : red[600],
        },
        success: {
          main: isDark ? green[200] : green[600],
        },
        info: {
          main: isDark ? lightBlue[200] : lightBlue[600],
        },
        warning: {
          main: isDark ? orange[200] : orange[600],
        },
      },
    },
    ptBR
  );
};

export const MuiThemeProvider = ({ children }: PropsWithChildren) => {
  const isDarkTheme = useMediaQuery("(prefers-color-scheme: dark")
    ? "dark"
    : "light";

  const loadedTheme = theme({ systemPreference: isDarkTheme });

  return <ThemeProvider theme={loadedTheme}>{children}</ThemeProvider>;
};
