import {
  Alert,
  AlertColor,
  AlertPropsColorOverrides,
  Snackbar,
  SnackbarOrigin,
} from "@mui/material";
import { PropsWithChildren, ReactNode, useState } from "react";
import { OverridableStringUnion } from "@mui/types";
import { GlobalSnackbarContext } from "./context";

export const GlobalSnackbarProvider = ({ children }: PropsWithChildren) => {
  const [visibility, setVisibility] = useState(false);
  const [anchorOrigin, setAnchorOrigin] = useState<SnackbarOrigin>({
    vertical: "top",
    horizontal: "right",
  });
  const [severity, setSeverity] =
    useState<OverridableStringUnion<AlertColor, AlertPropsColorOverrides>>(
      "info"
    );
  const [message, setMessage] = useState<ReactNode>("");
  const [timeout, setTimeoutState] = useState<number | undefined>(3000);

  const open = () => setVisibility(true);
  const close = () => {
    setAnchorOrigin({ vertical: "top", horizontal: "right" });
    setSeverity("info");
    setMessage("");
    setVisibility(false);
  };

  return (
    <GlobalSnackbarContext.Provider
      value={{
        showSnackbar: ({ anchorOrigin, severity, message, timeout }) => {
          if (anchorOrigin) setAnchorOrigin(anchorOrigin);
          if (severity) setSeverity(severity);
          if (message) {
            setMessage(message);
            open();
          }
          if (timeout) setTimeoutState(timeout);
        },
      }}
    >
      {children}
      <Snackbar
        open={visibility}
        onClose={close}
        anchorOrigin={anchorOrigin}
        autoHideDuration={timeout}
      >
        <Alert onClose={close} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </GlobalSnackbarContext.Provider>
  );
};
