import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import { ROUTES_PATH } from "./shared/constants/routes";
import { adminLayoutLoader } from "./modules/layouts/admin-layout/loader";
import { authLoader } from "./modules/auth/auth-loader";
import { FullscreenLoading } from "./shared/components/loadings/fullscreen-loading";
import { Suspense, lazy } from "react";
import { menuManageInvitesLoader } from "./modules/manage-invites/loader";
import { establishmentsLoader } from "./modules/establishments/loader";
import { establishmentLoader } from "./modules/layouts/establishment-layout/loader";
import ErrorView from "./shared/components/error";

// Lazy load the components
const AuthLayout = lazy(() =>
  import("./modules/layouts/auth-layout").then((module) => ({
    default: module.AuthLayout,
  }))
);
const AuthLogin = lazy(() =>
  import("./modules/auth/pages/login").then((module) => ({
    default: module.AuthLogin,
  }))
);
const Menu = lazy(() =>
  import("./modules/menu/pages").then((module) => ({ default: module.Menu }))
);
const Profile = lazy(() =>
  import("./modules/profile/pages").then((module) => ({
    default: module.Profile,
  }))
);
const ManageInvites = lazy(() =>
  import("./modules/manage-invites/pages").then((module) => ({
    default: module.ManageInvites,
  }))
);
const AdminLayout = lazy(() =>
  import("./modules/layouts/admin-layout").then((module) => ({
    default: module.AdminLayout,
  }))
);
const Establishments = lazy(() =>
  import("./modules/establishments/pages").then((module) => ({
    default: module.Establishments,
  }))
);
const Establishment = lazy(() =>
  import("./modules/establishment/pages").then((module) => ({
    default: module.Establishment,
  }))
);
const EstablishmentLayout = lazy(() =>
  import("./modules/layouts/establishment-layout").then((module) => ({
    default: module.EstablishmentLayout,
  }))
);
const EstablishmentServiceTypes = lazy(() =>
  import("./modules/service-types/pages").then((module) => ({
    default: module.EstablishmentServiceTypes,
  }))
);
const EstablishmentPaymentMethod = lazy(() =>
  import("./modules/payments/pages").then((module) => ({
    default: module.EstablishmentPaymentMethod,
  }))
);
const EstablishmentCustomers = lazy(() =>
  import("./modules/customers/pages").then((module) => ({
    default: module.EstablishmentCustomers,
  }))
);
const EstablishmentManageBarbers = lazy(() =>
  import("./modules/manage-barbers/pages").then((module) => ({
    default: module.EstablishmentManageBarbers,
  }))
);
const EstablishmentPendingAppointments = lazy(() =>
  import("./modules/appointments/pages").then((module) => ({
    default: module.EstablishmentPendingAppointments,
  }))
);
const AuthForgotPassword = lazy(() =>
  import("./modules/auth/pages/forgot-password").then((module) => ({
    default: module.AuthForgotPassword,
  }))
);
const AuthForgotPasswordConfirm = lazy(() =>
  import("./modules/auth/pages/forgot-password-confirm").then((module) => ({
    default: module.AuthForgotPasswordConfirm,
  }))
);
const AuthRegister = lazy(() =>
  import("./modules/auth/pages/register").then((module) => ({
    default: module.AuthRegister,
  }))
);
const AuthRegisterConfirm = lazy(() =>
  import("./modules/auth/pages/register-confirm").then((module) => ({
    default: module.AuthRegisterConfirm,
  }))
);
const SetupLayout = lazy(() =>
  import("./modules/layouts/setup-layout").then((module) => ({
    default: module.SetupLayout,
  }))
);

const ProductExpense = lazy(() =>
  import("./modules/product-expenses/pages").then((module) => ({
    default: module.ProductExpensesPage,
  }))
);

const WhatsApp = lazy(() =>
  import("./modules/whatsapp/pages").then((module) => ({
    default: module.WhatsAppPage,
  }))
);

const router = createBrowserRouter([
  {
    element: <SetupLayout />,
    children: [
      {
        path: ROUTES_PATH.HOME,
        loader: async () => redirect(ROUTES_PATH.AUTH),
        errorElement: <ErrorView />,
      },
      {
        element: <AuthLayout />,
        loader: authLoader,
        errorElement: <ErrorView />,
        children: [
          {
            path: ROUTES_PATH.AUTH,
            element: <AuthLogin />,
          },
          {
            path: ROUTES_PATH.AUTH_REGISTER,
            element: <AuthRegister />,
          },
          {
            path: ROUTES_PATH.AUTH_REGISTER_CONFIRM,
            element: <AuthRegisterConfirm />,
          },
          {
            path: ROUTES_PATH.AUTH_FORGOT_PASSWORD,
            element: <AuthForgotPassword />,
          },
          {
            path: ROUTES_PATH.AUTH_FORGOT_PASSWORD_CONFIRM,
            element: <AuthForgotPasswordConfirm />,
          },
        ],
      },
      {
        loader: adminLayoutLoader,
        element: <AdminLayout />,
        errorElement: <ErrorView />,
        children: [
          {
            children: [
              {
                path: ROUTES_PATH.MENU,
                element: <Menu />,
              },
              {
                path: ROUTES_PATH.MENU_PROFILE,
                element: <Profile />,
              },
              {
                loader: menuManageInvitesLoader,
                path: ROUTES_PATH.MENU_MANAGE_INVITES,
                element: <ManageInvites />,
              },
            ],
          },
          {
            loader: establishmentsLoader,
            path: ROUTES_PATH.ESTABLISHMENTS,
            element: <Establishments />,
            errorElement: <ErrorView />,
          },
          {
            element: <EstablishmentLayout />,
            loader: establishmentLoader,
            errorElement: <ErrorView />,
            children: [
              {
                path: ROUTES_PATH.ESTABLISHMENT,
                element: <Establishment />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_SERVICE_TYPES,
                element: <EstablishmentServiceTypes />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_PAYMENTS,
                element: <EstablishmentPaymentMethod />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_CUSTOMERS,
                element: <EstablishmentCustomers />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_MANAGE_BARBERS,
                element: <EstablishmentManageBarbers />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_PENDING_APPOINTMENTS,
                element: <EstablishmentPendingAppointments />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_PRODUCT_EXPENSES,
                element: <ProductExpense />,
              },
              {
                path: ROUTES_PATH.ESTABLISHMENT_WHATSAPP,
                element: <WhatsApp />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export const AppRouter = () => {
  return (
    <Suspense fallback={<FullscreenLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
