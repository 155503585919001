import "@fontsource-variable/montserrat";

import { AppProviders } from "@/contexts/app/provider";
import { AppRouter } from "@/router";
import { ErrorBoundary } from "@sentry/react";
import ErrorView from "./shared/components/error";

function App() {
  return (
    <ErrorBoundary fallback={<ErrorView />}>
      <AppProviders>
        <AppRouter />
      </AppProviders>
    </ErrorBoundary>
  );
}

export default App;
