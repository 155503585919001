export const ROUTES_PATH = {
  HOME: "/",
  AUTH: "/entrar",
  AUTH_REGISTER: "/register",
  AUTH_REGISTER_CONFIRM: "/register/confirm",
  AUTH_FORGOT_PASSWORD: "/forgot-password",
  AUTH_FORGOT_PASSWORD_CONFIRM: "/forgot-password/new",
  ESTABLISHMENTS: "/establishments",
  ESTABLISHMENT: "/establishments/:establishmentId",
  ESTABLISHMENT_SERVICE_TYPES: "/establishments/:establishmentId/service-types",
  ESTABLISHMENT_PAYMENTS: "/establishments/:establishmentId/payments",
  ESTABLISHMENT_CUSTOMERS: "/establishments/:establishmentId/customers",
  ESTABLISHMENT_MANAGE_BARBERS:
    "/establishments/:establishmentId/manage-barbers",
  ESTABLISHMENT_PENDING_APPOINTMENTS:
    "/establishments/:establishmentId/pending-appointments",
  ESTABLISHMENT_PRODUCT_EXPENSES:
    "/establishments/:establishmentId/product-expenses",
  ESTABLISHMENT_WHATSAPP: "/establishments/:establishmentId/whatsapp",
  MENU: "/menu",
  MENU_PROFILE: "/menu/profile",
  MENU_MANAGE_INVITES: "/menu/manage-invites",
};

export const EXTERNAL_LINKS = {
  YOUTUBE_UPDATES:
    "https://www.youtube.com/playlist?list=PLQCetXd7u31HZvj_yXKPhL_SbA4BLXud7",
};
